import WebServiceRequest from '../Api/WebServiceRequest'

class GetAllServiceDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`ServiceDiscount/GetAll`);
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

class DeleteServiceDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag(`ServiceDiscount/Delete`);
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}


class UpdateServiceDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ServiceDiscount/Update');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}

class CreateServiceDiscount extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ServiceDiscount/Create');
    }

    setData(data) {
        super.setRequestParamDataObj(data)
    }
}
class GetVendorServicePrices extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ServicePrice/GetVendorServicePrices');
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}
export {
    GetAllServiceDiscount,
    DeleteServiceDiscount,
    UpdateServiceDiscount,
    CreateServiceDiscount,
    GetVendorServicePrices
};
